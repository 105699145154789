<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Employee
          <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row class="text-center">
                      <v-col cols="12" md="4">
                        <v-img style="cursor:pointer" :src="edit_data ? img : imgurl" @click="$refs.foto_profile.click()"></v-img>
                        <span style="color:red">Image max 300Kb</span>
                      </v-col>
                      <v-col cols="12" md="8">
                          <v-text-field
                          v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required
                          ></v-text-field>

                          <v-text-field
                          v-model="nik"
                          label="NIK"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="phone"
                          :rules="phoneRules"
                          label="Phone"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          ></v-text-field>

                          <v-autocomplete
                          v-model="jabatan_id"
                          :items="jabatans"
                          chips
                          label="Jobtitle"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('jabatan_id')"
                              >

                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>

                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                          v-model="branch_id"
                          :items="branchs"
                          chips
                          label="Branch"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('branch_id')"
                              >

                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>

                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-textarea
                            v-model="address"
                            label="Address"
                          ></v-textarea>
                      </v-col>
                    </v-row>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MixinGlobal from '../../../mixin/index'
export default {
  props: ['dialog', 'edit_data'],
  mixins:[MixinGlobal],
  methods: {
    send_data() {
      const data = new FormData()
      data.append('phone', this.phone)
      data.append('photos', this.foto)
      data.append('email', this.email)
      data.append('address', this.address)
      data.append('jabatan_id', this.jabatan_id)
      data.append('branch_id', this.branch_id)
      data.append('nik', this.nik)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/karyawan/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('karyawan','employees')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/karyawan/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('karyawan','employees')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    eventChange (event) {
      const files = event.target.files
      if (files[0].size > 300 * 1024) {
        this.setSnakbar({
            status: true,
            pesan: 'Image size to large...',
            color_snakbar: 'red'
          })
      } else {
        this.foto = files[0]
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.imgurl = fileReader.result
        })
        fileReader.readAsDataURL(this.foto)
      }

    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.phone = ''
      this.foto = ''
      this.email = ''
      this.jabatan_id = ''
      this.branch_id = ''
      this.address = ''
      this.nik = ''
      this.name = ' '
      this.imgurl = 'img/user.png'
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    nik: '',
    phone: '',
    email: '',
    jabatan_id: '',
    branch_id: '',
    address: '',
    password: '',
    phoneRules: [
      (v) => !v || /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(v) || 'Please enter a valid Phone number (ex. 0857XXXXXX)'
    ],
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    nameRules: [
      v => !!v || 'Field is Required'
    ],
    imgurl: 'img/user.png',
    foto: null

  }),

  computed: {
    img: function () {
      if (this.edit_data) {
        if (this.edit_data.image_link) {
          return process.env.VUE_APP_IMGLINK + 'karyawan/' + this.edit_data.image_link
        } else {
          return this.imgurl
        }
      } else {
        return this.imgurl
      }
    },
      ...mapGetters({
      jabatans: 'data/jabatans',
      branchs: 'data/branchs',
    }),
  },

  async created () {

    if (this.edit_data) {
      this.phone = this.edit_data.phone
      this.email = this.edit_data.email
      this.address = this.edit_data.address
      this.nik = this.edit_data.nik
      this.name = this.edit_data.name
      this.jabatan_id = this.edit_data.jabatan_id
      this.branch_id = this.edit_data.branch_id
    }
    await this.get_data_master('jabatan','jabatans')
      await this.get_data_master('branch','branchs')
  }
}
</script>
